#experience {
  background-image: linear-gradient(to bottom right, #d2eaf4, #f2a6d1);
  padding-bottom: 4rem;
  color: black;
}

.vertical-timeline--two-columns
  .vertical-timeline-element-content
  .vertical-timeline-element-date {
  color: black;
  font-weight: bolder;
  text-shadow: 1px 1px white;
  font-size: 1.5rem;
  margin-top: -0.5rem;
}

@media screen and (max-width: 768px) {
  .vertical-timeline--two-columns
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    color: white;
    text-shadow: 1px 1px black;
    margin-top: 0rem;
      font-size: 1rem;

  }
}
