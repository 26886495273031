#project {
  padding-bottom: 3rem;
}

.portfolio_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  width: 80%;
  margin: auto;
  margin-top: 4rem;
}

.portfolio_item {
  background: var(--color-bg-variant);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
  position: relative;
}

.portfolio_item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

/* button design part of the website */

.category_button {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 2rem;
}

.category_button button {
  color: black;
  font-weight: 700;
  letter-spacing: 1.5px;
  box-shadow: 0px 1px 6px -3px black;
  border: 1px solid aliceblue;
}

.Headline_Project {
  color: black;
  font-weight: 900;
  font-size: 2rem;
}

.portfolio_item_image {
  border-radius: 10px;
  overflow: hidden;
}

.tag {
  display: flex;
  justify-content: center;
  gap: 2.5px;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #6de085;
  border-radius: 1rem;
  height: 2rem;
  padding: 3px 9px;
  text-align: center;
  font-size: 12px;
}

.badge {
  color: red;
  font-size: 1.5rem;
}

.portfolio_item_image img {
  width: 100%;
  height: 13rem;
}

.portfolio_item_image:hover {
  -webkit-box-shadow: -1px -3px 47px -15px rgba(0, 0, 0, 1);
  -moz-box-shadow: -1px -3px 47px -15px rgba(0, 0, 0, 1);
  box-shadow: -1px -3px 47px -15px rgba(0, 0, 0, 1);
}

.portfolio_item h3 {
  font-weight: bold;
  margin: 5px 2rem;
  text-align: center;
  letter-spacing: 1px;
}

.portfolio_item p {
  text-align: left;
  margin: 15px 0px;
}

.portfolio_item_cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.more-project-button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

/* CSS */
.button-29 {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #5adaff 0,
    #5468ff 100%
  );
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.button-29:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-29:active {
  box-shadow: #3c4fe0 0 3px 7px inset;
  transform: translateY(2px);
}

/* MEDIA QUERY OF THE WEBSITE  */

@media screen and (max-width: 1200px) {
  .portfolio_container {
    width: 90%;
  }
}

@media screen and (max-width: 1024px) {
  .portfolio_container {
    grid-template-columns: 1fr 1fr;
    gap: 1.4rem;
  }
}

@media screen and (max-width: 600px) {
  .portfolio_container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .category_button {
    gap: 1rem;
  }
}

@media screen and (max-width: 400px) {
  .portfolio_container {
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 96%;
  }

  .category_button {
    gap: 5px;
    margin: 5px;
    margin-bottom: 1.5rem;
  }
  .category_button button {
    font-size: 10px;
  }
  .portfolio_item_image img {
    width: 100%;
    height: 9rem;
  }
}
