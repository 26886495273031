@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  outline: 0;
  /* list-style: none; */
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  text-decoration: none;
}

:root {
  --color-bg: #ffffff;
  --color-bg-variant: rgb(216, 230, 247);
  --color-primary: #0c90c8;
  --color-primary-variant: Orgba(77, 181, 255, 0.4);
  --color-white: #0c90c8(223, 32, 32);
  --color-light: Irgba(255, 255, 255, 0.6);
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
  --transition: all 400ms ease;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: rgb(177, 0, 0);
  border-radius: 100vw;
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(98, 15, 0);
}

body {
  background-color: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

/* #################   GENERAL STYLING OF THE WEBSITE  ############## */

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 3rem;
}

section {
  padding-top: 7rem;
}

section > h2,
section > h5 {
  text-align: center;
  color: var(--color-light);
  font-size: 1.7rem;
}

section > h2 {
  color: var(--color-primary);
  margin-bottom: 2rem;
  font-size: 1.7rem;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  color: black;
  background: var(--color-white);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
  font-weight: bold;
}
.btn-primary:hover {
  background: var(--color-white);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* ############  media query of the website ########## */

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
  section {
    margin-top: 6rem;
  }
}

/* ########### NEXT MEDIA QUERY FOR OTHER DIVICES   ########### */

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }
  section > h2 {
    margin-top: 2rem;
  }
}
