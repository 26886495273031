.Navbar_container {
  padding: 10px 10px;
  position: fixed;
  background-image: linear-gradient(to bottom right, #edf2f8, #edf2f8);
  width: 100%;
  height: 5rem;
  top: 0;
  left: 0;
  z-index: 10000000;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: fixed;
  width: 90%;
  right: 20px;
}

nav ul li {
  display: inline-block;
  margin: 10px 20px;
}

nav ul li a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 18px;
  position: relative;
  /* font-weight: bolder; */
  letter-spacing: 1px;
}

nav ul li a::after {
  content: "";
  width: 0%;
  height: 5px;
  background: #057ac2;
  position: absolute;
  left: 0;
  bottom: -6px;
  transition: 0.5s;
  border-radius: 10rem;
}

nav ul li a:hover::after {
  width: 100%;
}

.commonIcon {
  display: none;
  cursor: pointer;
}

/* _________________________media query for small screen  */

@media only screen and (max-width: 800px) {
  .commonIcon {
    display: block;
    font-size: 30px;
    color: black;
  }

  .donotshownavbar {
    background: #ffffff;
    position: fixed;
    top: 0;
    right: -50rem; /* Change the value of right to -200px to hide and to show change it to back 0 */
    width: 100%;
    height: 100vh;
    padding-top: 50px;
    z-index: 2;
    transition: right 0.5s;
  }

  .shownavbar {
    background: #ffffff;
    position: fixed;
    top: 0;
    right: 0; /* Change the value of right to -200px to hide and to show change it to back 0 */
    width: 100%;
    height: 100vh;
    padding-top: 50px;
    z-index: 2;
    transition: right 0.5s;
  }
  nav ul li {
    display: block;
    margin: 25px;
    text-align: center;
  }

  nav ul li a {
    font-size: 2rem;
  }
  nav ul .crossicon {
    position: absolute;
    top: 25px;
    /* left: 25px; */
    right: 25px;
    cursor: pointer;
    /* border: 2px solid black; */
    align-items: right;
  }
}
